import classNames from 'classnames';
import Image from 'next/image';

interface Props {
  className?: string;
  inverse?: boolean;
}

const Logo = ({ className = '', inverse = false }: Props) => (
  <div className={classNames('relative', className)}>
    <Image
      src={inverse ? '/logo-inverse.png' : '/logo.png'}
      alt={'Selfmade'}
      fill
      className="object-cover"
    />
  </div>
);

export default Logo;
